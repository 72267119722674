import React from 'react';

const Loading = () => {
  return (
    <div className="ui container">
      <div className="ui dimmer active">
        <div className="ui large text loader">beep boop raaar...</div>
      </div>
  </div>
  );
}

export default Loading;
