import React from 'react';
import { DISCORD_CLIENT_ID } from '../config.json';

const Landing = ({history}) => {

  const onSeeMovesClick = () => {
    history.push('/moves/list');
  }

  const addToServerUrl = `https://discord.com/oauth2/authorize?client_id=${DISCORD_CLIENT_ID}&scope=applications.commands%20bot`;

  return (
    <div id="top-centered-item" className="ui vertical masthead center aligned segment">
      <div className="ui container">
        <div className="ui text container">
        <img src="monsterbot_white.png" alt="monsterbot" width="231px" height="173px"></img>
        <h3 id="landing-greeting">bleep bloop snarl blorp raaar...</h3>
          <a
            className='ui primary button'
            href={addToServerUrl}
            rel="noopener noreferrer"
            target="_blank"
          >
            Add to Server
            <i className="right external alternate icon"></i>
          </a>

          <div onClick={onSeeMovesClick} className="ui secondary button">
            See the moves
            <i className="right arrow icon"></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
